import React, { useState } from 'react'

import { Box, useMediaQuery } from '@mui/material'
import { Radio } from 'antd'
import { FLAG_FEATURES } from 'api/src/common/enums'
import {
  AncillaryTableQuery,
  AncillaryTableQueryVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import AncilTable from 'src/components/Ancil/AncilTable'
import { ANCIL_TABLE_QUERY } from 'src/components/Ancil/gql/queries'
import { TableFilterIconButton } from 'src/components/Ancil/TableFilterIconButton'
import TabNavigation, { TabsEnum } from 'src/components/Ancil/TabNavigation'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

import PageHeader from '../../components/PageHeader/PageHeader'

export type AncillaryLead = AncillaryTableQuery['ancillaryLeads'][0]

const AncilPage = () => {
  const [showActiveOnly, setShowActiveOnly] = useState(true)
  const [query, setQuery] = useState<string>('')
  const queryParams = new URLSearchParams(location.search)
  const currentTab = queryParams.get('tab') || TabsEnum.NAME

  const handleTabChange = (newTab: string) => {
    navigate(`${routes.ancil()}?tab=${newTab}`)
  }

  const { data, loading, error } = useQuery<
    AncillaryTableQuery,
    AncillaryTableQueryVariables
  >(ANCIL_TABLE_QUERY, {
    variables: { onlyActive: showActiveOnly },
  })

  const isSmallDisplay = useMediaQuery('(max-width:1500px)')

  return (
    <>
      <Metadata title="Ancillary Services" description="Ancillary Services" />
      <PageHeader
        title="Ancillary Services"
        parentDataTestId="audit-log-page"
      />
      <FeatureToggle
        feature={FLAG_FEATURES.ANCIL}
        InactiveComponent={<FeatureDenied />}
      >
        <Box
          p={3}
          pb={2}
          className={'flex w-full items-center justify-between'}
        >
          <Box className={'flex'} gap={3}>
            <Box>
              <SearchField
                value={query}
                onChange={setQuery}
                className={'!w-[350px]'}
              />
            </Box>
            <Box className={'-mt-2'}>
              <TabNavigation tab={currentTab} onTabChange={handleTabChange} />
            </Box>
          </Box>
          {isSmallDisplay ? (
            <TableFilterIconButton
              showActiveOnly={showActiveOnly}
              setShowActiveOnly={setShowActiveOnly}
            />
          ) : (
            <Radio.Group
              value={showActiveOnly}
              buttonStyle="solid"
              onChange={(e) => setShowActiveOnly(e.target.value)}
            >
              <Radio.Button value={true}>{'Active Only'}</Radio.Button>
              <Radio.Button value={false}>{'All'}</Radio.Button>
            </Radio.Group>
          )}
        </Box>
        <Box p={3} pt={0}>
          <AncilTable
            ancillaryLeads={data?.ancillaryLeads || []}
            loading={loading}
            error={error}
            activeTab={currentTab as TabsEnum}
            query={query}
          />
        </Box>
      </FeatureToggle>
    </>
  )
}

export default AncilPage
