import React, { useState } from 'react'

import FilterIcon from '@mui/icons-material/FilterAltOutlined'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { Badge } from 'antd'

const renderRadioIcon = (isSelected: boolean) =>
  isSelected ? (
    <RadioButtonCheckedIcon fontSize="small" className={'text-indigo-600'} />
  ) : (
    <RadioButtonUncheckedIcon fontSize="small" />
  )

export const TableFilterIconButton = ({
  showActiveOnly,
  setShowActiveOnly,
}: {
  showActiveOnly: boolean
  setShowActiveOnly: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        className={'bg-gray-100 hover:bg-gray-100'}
        onClick={handleMenuOpen}
        disableRipple
      >
        <Badge color={'#625FFF'} dot={showActiveOnly !== true}>
          <FilterIcon />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <Box px={2} py={1} fontWeight="bold">
          Display Options
        </Box>
        <MenuItem
          selected={showActiveOnly === true}
          onClick={() => {
            setShowActiveOnly(true)
            handleMenuClose()
          }}
        >
          {renderRadioIcon(showActiveOnly === true)}
          <Box ml={1}>Active Only</Box>
        </MenuItem>
        <MenuItem
          selected={showActiveOnly === false}
          onClick={() => {
            setShowActiveOnly(false)
            handleMenuClose()
          }}
        >
          {renderRadioIcon(showActiveOnly === false)}
          <Box ml={1}>All</Box>
        </MenuItem>
      </Menu>
    </>
  )
}
